<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Soon',

    metaInfo: { title: 'Soon...' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'soon',
        'newsletter',
        'info-alt',
      ]),
    ],
    /****
     *         'News',
        'Projects',
        'Project',
        'Mobile',
        'InfoGraph',
        'CustomerReview',
        'RecentWorks',
        'Affiliates',
        'Article',
        'WeHelpYourSuccess',
     */

    props: {
      id: {
        type: String,
        default: 'soon',
      },
    },
  }
</script>
